import { stAnalytics } from "@repo/analytics";
import { Recipient, type curator } from "@repo/client";
import { Named } from "@repo/logger";
import { getContentTypes } from "@repo/mime";
import { BrowserStorage } from "@repo/storage";
import { useLocation, useNavigate } from "@solidjs/router";
import type { Editor, JSONContent } from "@tiptap/core";
import {
  type Accessor,
  type ParentComponent,
  type Setter,
  createContext,
  createEffect,
  createSignal,
  onCleanup,
  onMount,
  useContext,
} from "solid-js";
import { type SetStoreFunction, createStore, unwrap } from "solid-js/store";
import { createEditor } from "tiptap-solid";
import { useThreadEventProperties } from "~/domains/analytics/useThreadEventProperties";
import { selectIsIdentityConnecting, useIsIdentityConnecting } from "~/domains/identity/hooks";
import { WithLimitedFileTypes, WithMultipleFiles, useAssetUploader } from "~/domains/projects/hooks/assetUpload";
import { blankKnowledge, newPromptWithDefaults, newTransformationPrompt } from "~/domains/threads/prompt/prompt";
import { formatCollectionName } from "~/lib/ui/formatCollectionName";
import { isHomePage, urls } from "~/lib/urls";
import { useUIState } from "~/ui/UIState";
import { type Wire, useWire } from "~/wire";
import type { PromptProps } from "./PersistentPrompt";
import { ChatFileUpload, type ChatFileUploadStrategy } from "./components/ChatFileUpload";
import { getEditorExtensions } from "./extensions/getExtensions";
import { getPromptTextAndDecorations } from "./getPromptTextAndDecorations";
import { getFirstKnownModelMentionFromText } from "./helpers";
import { insertPromptWithAnimation } from "./insertPromptWithAnimation";
import { transformPastedHTML, transformPastedText } from "./pasteTransform";
export type PromptSettings = {
  submitKeybinding: "enter" | "shift+enter";
};

export const highlights = ["send-button"] as const;
export type HighlightId = (typeof highlights)[number];

type PromptContextValue = {
  changeKnowledge: (strategy: ChatFileUploadStrategy) => Promise<void>;
  activeCollection: Accessor<curator.CollectionSnapshot | undefined>;
  editor: Accessor<Editor | null>;
  focused: Accessor<boolean>;
  highlights: Accessor<HighlightId[]>;
  isReadyToSubmit: () => boolean;
  isConnecting: Accessor<boolean>;
  promptRef: Accessor<HTMLElement | undefined>;
  promptSettings: PromptSettings;
  rawProps: PromptProps;
  removeHighlight: (h: HighlightId) => void;
  addHighlight: (h: HighlightId) => void;
  setFocused: Setter<boolean>;
  setPromptRef: Setter<HTMLElement | undefined>;
  setPromptSettings: SetStoreFunction<PromptSettings>;
  /**
   * Types text into the chat UI one letter at a type, creating a typing effect
   *
   * If you don't need to show the typing effect and just submit a prompt immediately, use `submitPrompt` instead and pass a `customPrompt`
   * @param text Text to write
   * @param instant If the prompt gets typed out or instantly appears in the UI
   * @returns
   */
  typePrompt: (
    text: string | JSONContent,
    opts?: Partial<{
      instant: boolean;
      highlight: boolean;
    }>,
  ) => Promise<void>;
  submitPrompt: (
    /**
     * Prompts can be submitted either from the prompt chat box UI or with a custom prompt.
     * Leave `customPrompt` undefined if you want the prompt to be picked up from the prompt UI.
     */
    customPrompt?: {
      text: string;
      mentionedAssets: string[];
    },
  ) => Promise<void>;
  uploader: ReturnType<typeof useAssetUploader>;
  promptPositioning: Accessor<
    | {
        x: number;
        y: number;
      }
    | undefined
  >;
  setPromptPositioning: Setter<
    | {
        x: number;
        y: number;
      }
    | undefined
  >;
  showUploadModal: Accessor<boolean>;
  setShowUploadModal: Setter<boolean>;
  showQuestionLibrary: Accessor<boolean>;
  setShowQuestionLibrary: Setter<boolean>;
  transformationId: Accessor<string | undefined>;
  setTransformationId: Setter<string | undefined>;
};

const PromptContext = createContext<PromptContextValue>();

export const usePromptContext = () => {
  const ctx = useContext(PromptContext);
  if (!ctx) throw Error("No PromptContext found");
  return ctx;
};

const useAssetUploaderWithDiffs = (wire: Wire) => {
  const uploader = useAssetUploader(
    {
      logger: wire.dependencies.logger,
      wire,
    },
    WithMultipleFiles(),
    WithLimitedFileTypes(getContentTypes()),
  );

  const generateDiff = (referencedOutsideUploader?: {
    added: string[];
    removed: string[];
  }) => {
    // Calculate any changes to our knowledge base
    const current = uploader
      .assets()
      .filter((a) => a.snapshot.value === "Done")
      .map((a) => a.snapshot.context.assetID)
      .filter((a) => a) as string[];

    referencedOutsideUploader?.added?.forEach((a) => {
      if (!current.includes(a)) current.push(a);
    });

    const addedAssets = current;
    const removedAssets: string[] = referencedOutsideUploader?.removed || [];
    const useWorldKnowledge = current.length === 0;

    return {
      addedAssets,
      removedAssets,
      useWorldKnowledge,
    };
  };

  return {
    uploader,
    generateDiff,
  };
};

export const PromptContextProvider: ParentComponent<PromptProps> = (props) => {
  const wire = useWire();
  const ui = useUIState();
  const logger = new Named(wire.dependencies.logger, "PersistentPrompt");
  const navigate = useNavigate();
  const location = useLocation();
  const isHomeScreen = () => isHomePage(location);
  const isConnecting = useIsIdentityConnecting();
  const { threadEventProps } = useThreadEventProperties();
  const activeCollection = () => props.activeCollection() || wire.services.collections.getPersonalRoot();
  const [, setCollectionId] = ui.collectionId;
  const [, setThreadId] = ui.threadId;
  const [usePublicKnowledge] = ui.usePublicKnowledge;

  let oldLabel: undefined | string = undefined;
  createEffect(() => {
    const active = activeCollection();

    if (!active) return;
    setCollectionId(active.id);

    const editor = editorObj.editor();
    if (!editor) return;

    let placeholder = "What do you want to accomplish?";
    if (isHomeScreen()) {
      placeholder = "What insights would you like? Just ask Storytell to get started.";
    }
    if (!isHomeScreen()) {
      if (usePublicKnowledge() === "collection") {
        placeholder = `Chat with your ${formatCollectionName(active.label)}.`;
      } else if (usePublicKnowledge() === "public") {
        placeholder = "Chat with Public Knowledge";
      } else if (usePublicKnowledge() === "both") {
        placeholder = `Chat with your ${formatCollectionName(active.label)} and Public Knowledge.`;
      }
    }
    if (oldLabel === placeholder) return;
    oldLabel = placeholder;
    editor.commands.setPlaceholder(placeholder);
  });

  const { uploader, generateDiff } = useAssetUploaderWithDiffs(wire);
  const [showUploadModal, setShowUploadModal] = createSignal(false);
  const isReadyToSubmit = () => {
    const threadMachineState = wire.services.threads.snapshot.value;
    return !isConnecting() && (threadMachineState === "idle" || threadMachineState === "ready");
  };

  // allowNavigate is a signal that is set to false when we're in a thread, and true when we're not. The true
  // signal will be sent in our onSubmit once we have successfully submitted a prompt. This helps us assert that the
  // prompt has been processed so we don't lose any data on the navigation when we enter the `resume` state.
  const [allowNavigate, setAllowNavigate] = createSignal(!wire.services.threads.snapshot?.context?.threadId);
  const [promptRef, setPromptRef] = createSignal<HTMLElement>();
  const [transformationId, setTransformationId] = createSignal(props.transformationID);
  const [focused, setFocused] = createSignal(!!props.alwaysFocused);
  const [showQuestionLibrary, setShowQuestionLibrary] = createSignal(false);
  const [promptSettings, setPromptSettings] = createStore<PromptSettings>({
    submitKeybinding: "enter",
  });
  const [highlights, setHighlights] = createSignal<HighlightId[]>([]);
  const addHighlight = (h: HighlightId) =>
    setHighlights((hs) => {
      if (hs.includes(h)) return hs;
      return [h, ...hs];
    });
  const removeHighlight = (h: HighlightId) => setHighlights((hs) => hs.filter((hs) => hs !== h));

  const [promptPositioning, setPromptPositioning] = createSignal<{
    x: number;
    y: number;
  }>();

  const editorObj = {
    editor: () => null,
  } as { editor: Accessor<Editor | null> };

  //  !Important - Navigate to the thread if we have a threadId
  createEffect(() => {
    const threadId = wire.services.threads.snapshot?.context?.threadId;
    setThreadId(threadId ?? "");
    if (threadId && allowNavigate() && location.pathname !== urls.threadV2(threadId)) {
      navigate(urls.threadV2(threadId));
    }
  });

  let lastSubmitted = Date.now();
  const submitPrompt: PromptContextValue["submitPrompt"] = async (customPrompt) => {
    const now = Date.now();
    // Don't submit prompts withing 2 seconds of each other
    if (now - lastSubmitted <= 2000) {
      return;
    }
    lastSubmitted = now;

    const opts = {
      text: "",
      mentionedAssets: [] as string[],
      mentionedCollections: [] as string[],
    };

    const recipient = [Recipient.RecipientModel];

    if (customPrompt) {
      opts.text = customPrompt.text;
      opts.mentionedAssets = customPrompt.mentionedAssets;
    } else {
      const { text, mentionedAssets, mentionedCollections } = getPromptTextAndDecorations(editorObj.editor());
      opts.text = text || "";
      opts.mentionedAssets = mentionedAssets;
      opts.mentionedCollections = mentionedCollections;
    }

    if (!opts.text) {
      logger.warn("trying to submit message but no text");
      return;
    }

    const mentionedModel = getFirstKnownModelMentionFromText(opts.text);

    wire.services.limiting.guest.incrementInteractions({
      type: "prompt",
      prompt: opts.text,
    });

    if (!wire.services.limiting.guest.isInteractionAllowed()) return;

    if (props.disableSubmit) return;
    if (selectIsIdentityConnecting(wire.services.identity)) {
      logger.info(
        "trying to submit message but identity is not ready -- stopped",
        unwrap(wire.services.identity.snapshot.context.identity.workingContext),
      );
      return;
    }
    wire.services.websocket.connect();
    const payload = {};

    // onDone must be called regardless of the prompt we dispatch
    const onDone = () => {
      removeHighlight("send-button");
    };

    // A campaign prompt. We don't have any knowledge to add for a campaign prompt. Later, the user may take control
    // but at this stage we're simply introducing the user, and they can't have knowledge yet.
    const transfId = transformationId() || props.transformationID;
    if (transfId) {
      logger.info("submitting a transformation prompt", {
        text: editorObj.editor()?.getText(),
        recipient,
        payload,
        id: transfId,
      });
      const prompt = newTransformationPrompt(opts.text, transfId);
      await wire.services.threads.sendThreadPrompt({
        prompt,
        collectionId: activeCollection()?.id || "",
        tenantId: activeCollection()?.tenantId || "",
        organizationId: activeCollection()?.organizationId || "",
      });

      return onDone();
    }

    logger.info("submitting a user-controlled prompt", {
      text: editorObj.editor()?.getText(),
      recipient,
      payload,
    });

    const inThread = wire.services.threads.snapshot.context.activeAssets;

    // Calculate any changes to our knowledge base
    const { addedAssets, removedAssets } = generateDiff({
      // Only add assets that are not part of the thread knowledge already
      added: [],
      removed: [],
    });

    const prompt = newPromptWithDefaults(opts.text, "");

    const colId = props.activeCollection()?.id;

    // If there's an active Collection and no explicitly @ mentioned Collections,
    // Add the active Collection and all of its descendants to the scope of the prompt
    if (colId && !opts.mentionedCollections.length && !opts.mentionedAssets.length) {
      prompt.scope.collectionIDs.push(...wire.services.collections.getCollectionDescendantsIds(colId));
    }
    // If there are mentioned Collections, add only those and their descendants to
    // the scope of the prompt, ignore the active Collection
    if (opts.mentionedCollections.length) {
      for (const m of opts.mentionedCollections) {
        const unique = new Set<string>();
        unique.add(m);
        for (const d of wire.services.collections.getCollectionDescendantsIds(m)) {
          unique.add(d);
        }
        prompt.scope.mentions.collections.push(Array.from(unique));
      }
    }

    if (opts.mentionedAssets.length) {
      const unique = new Set<string>(opts.mentionedAssets);
      prompt.scope.mentions.assets = Array.from(unique).map((a) => [a]);
    }

    // TODO @andi Better scoping rules
    // Update knowledge context if any assets have been mentioned
    if (addedAssets.length) {
      prompt.scope.assetIDs = addedAssets;
    }

    if (mentionedModel) {
      prompt.model = mentionedModel;
    }

    /**
     * Use the user's preference for scope to override any prompt scope values
     */
    switch (ui.usePublicKnowledge[0]()) {
      case "collection":
        prompt.scope.worldKnowledge = false;
        break;
      case "public":
        prompt.scope.worldKnowledge = true;
        prompt.scope.collectionIDs = [];
        prompt.scope.assetIDs = [];
        break;
      case "both":
        prompt.scope.worldKnowledge = true;
        break;
    }

    await wire.services.threads.sendThreadPrompt({
      prompt,
      collectionId: activeCollection()?.id || "",
      tenantId: activeCollection()?.tenantId || "",
      organizationId: activeCollection()?.organizationId || "",
    });

    wire.services.knowledge.resetQueuedKnowledgeChange();
    setAllowNavigate(true);

    stAnalytics.track("prompt_submitted", {
      prompt: opts.text,
      ...threadEventProps(),
    });

    return onDone();
  };

  const changeKnowledge = async (strategy: ChatFileUploadStrategy) => {
    wire.services.limiting.guest.incrementInteractions({ type: "knowledge" });
    if (!wire.services.limiting.guest.isInteractionAllowed()) return;

    if (props.disableSubmit) return;
    if (selectIsIdentityConnecting(wire.services.identity)) {
      logger.info(
        "trying to submit knowledge change but identity is not ready -- stopped",
        unwrap(wire.services.identity.snapshot.context.identity.workingContext),
      );
      return;
    }

    // Prompt is responsible for managing the websocket connection. Given the user may not have submitted a prompt for
    // this current session, they may not have a websocket established. Let's try to connect.
    wire.services.websocket.connect();

    const { addedAssets, removedAssets, useWorldKnowledge } = generateDiff({
      added: [...wire.services.knowledge.queuedKnowledgeChange().added],
      removed: [...wire.services.knowledge.queuedKnowledgeChange().removed],
    });

    // Handling web content upload where the browser doesn't have access to the asset in the uploader
    if (strategy.type === "web") {
      addedAssets.push(strategy.assetId);
    }

    const knowledge: curator.Knowledge = {
      ...blankKnowledge,
      world: useWorldKnowledge,
      assetContext: {
        added: addedAssets,
        removed: removedAssets,
      },
    };

    wire.services.knowledge.resetQueuedKnowledgeChange();
    await wire.services.threads.sendThreadKnowledge({
      knowledge,
      collectionId: activeCollection()?.id || "",
      tenantId: activeCollection()?.tenantId || "",
      organizationId: activeCollection()?.organizationId || "",
    });

    setAllowNavigate(true);
  };

  const typePrompt = async (
    content: string | JSONContent,
    opts?: Partial<{
      instant: boolean;
      highlight: boolean;
    }>,
  ) => {
    editorObj.editor()?.commands.setContent("");
    if (opts?.instant) {
      editorObj.editor()?.commands.setContent(content);
      return;
    }

    const insertContent = (content: string | JSONContent) => editorObj.editor()?.commands.insertContent(content);
    const setContent = (content: string | JSONContent) => editorObj.editor()?.commands.setContent(content);

    await insertPromptWithAnimation(content, insertContent, setContent);

    if (opts?.highlight) {
      addHighlight("send-button");
    }
  };

  onMount(() => {
    onCleanup(() => {
      props.onCleanup?.(editorObj.editor());
      if (props.initialPrompt?.highlight && wire.services.limiting.guest.isInteractionAllowed()) {
        BrowserStorage.setIsPromptTutorialDone();
      }
    });
  });

  return (
    <PromptContext.Provider
      value={{
        editor: () => editorObj.editor(),
        submitPrompt,
        changeKnowledge,
        activeCollection,
        focused,
        setFocused: (args) => {
          if (props.alwaysFocused) return setFocused(true);
          // biome-ignore lint/suspicious/noExplicitAny: <explanation>
          return setFocused(args as any) || false;
        },
        promptRef,
        setPromptRef,
        isConnecting,
        isReadyToSubmit,
        promptSettings,
        setPromptSettings,
        highlights,
        removeHighlight,
        addHighlight,
        uploader,
        rawProps: props,
        promptPositioning,
        setPromptPositioning,
        typePrompt,
        showUploadModal,
        setShowUploadModal,
        showQuestionLibrary,
        setShowQuestionLibrary,
        transformationId,
        setTransformationId,
      }}
    >
      {/* A hacky-ish way to create the editor inside the prompt context provider, so that the editor has access
      to usePromptContext inside itself */}
      {(() => {
        onMount(() => {
          editorObj.editor = createEditor({
            extensions: getEditorExtensions(),
            editorProps: {
              attributes: {
                class:
                  "outline-none min-h-[1.8125rem] max-h-[224px] sm:max-h-[400px] overflow-y-auto break-words text-[16px]",
                "data-testid": "prompt-text-input",
                "aria-label": "Type your prompt",
              },
              transformPastedText(text) {
                return transformPastedText(text);
              },
              transformPastedHTML(html) {
                return transformPastedHTML(html);
              },
            },
            onCreate({ editor }) {
              if (props.autoFocusInput) {
                editor.commands.focus();
              }
              if (props.initialPrompt) {
                // Delay the initial prompt being typed out so that the user has a chance to
                // take in the rest of the page before noticing the typing effect
                setTimeout(() => {
                  editorObj.editor = () => editor;
                  if (props.initialPrompt)
                    typePrompt(props.initialPrompt.content, {
                      highlight: !!props.initialPrompt.highlight,
                    });
                }, props.initialPrompt.delay || 750);
              }
              wire.services.threads.updateEditorRef(editor);
            },
          });
        });

        return null;
      })()}
      {props.children}

      <ChatFileUpload
        context={props.inBackground ? "project" : "thread"}
        onUploaded={async (strategy) => {
          if (props.overrideOnFilesUploaded) {
            props.overrideOnFilesUploaded();
          } else {
            await changeKnowledge(strategy);
          }
        }}
        open={showUploadModal()}
        setOpen={(val: boolean) => {
          setShowUploadModal(val);
        }}
      />
    </PromptContext.Provider>
  );
};

import Mention from "@tiptap/extension-mention";
import { SolidNodeViewRenderer, SolidRenderer, getTiptapSolidReactiveOwner } from "tiptap-solid";
import { PluginKey } from "prosemirror-state";
import { useWire } from "~/wire";
import { runWithOwner } from "solid-js";
import type { SuggestionProps } from "@tiptap/suggestion";
import { MentionNode, MentionsContainer, type MentionsProps } from "./Mentions.render";
import { insertCharacterCommand } from "../helpers";
import { AssetLifecycleStates, type assets, getRequestClient } from "@repo/client";
import { CollectionKind, type CollectionSnapshot } from "~/domains/collections/collections.types";
import { searchAssetsAndCollections } from "~/lib/api/searchAssetsAndCollections";

export type MentionItemType =
  | {
      type: "asset";
      associatedCollection: string;
      data: assets.AssetSnapshot;
    }
  | {
      type: "collection";
      associatedCollection: string;
      data: CollectionSnapshot;
    };
export type MentionsSuggestionProps = SuggestionProps<MentionItemType>;

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    Mention: {
      invokeMention: () => ReturnType;
    };
  }
}

export const MentionsExtension = Mention.extend({
  name: "mentionNode",
  addAttributes() {
    return {
      type: "",
      associatedCollection: "",
      data: {},
    };
  },
  addNodeView() {
    return SolidNodeViewRenderer(MentionNode);
  },
  addCommands: () => ({
    invokeMention: () => insertCharacterCommand("@"),
  }),
}).configure({
  suggestion: {
    pluginKey: new PluginKey("mention"),
    char: "@",
    items: async ({ query, editor }): Promise<MentionItemType[]> => {
      const owner = getTiptapSolidReactiveOwner(editor);
      const wire = runWithOwner(owner, useWire);
      if (!wire) return [];
      return searchAssetsAndCollections(query, wire);
    },
    render: () => {
      let solidRenderer: SolidRenderer<MentionsProps> | undefined;
      return {
        onStart: (props: MentionsSuggestionProps) => {
          solidRenderer = new SolidRenderer(MentionsContainer, {
            props,
            editor: props.editor,
          });
          solidRenderer.updateProps(props);
        },
        onUpdate(props: MentionsSuggestionProps) {
          solidRenderer?.updateProps(props);
        },
        onExit() {
          solidRenderer?.destroy();
          solidRenderer = undefined;
        },

        onKeyDown(props) {
          if (props.event.key === "Escape") {
            solidRenderer?.destroy();
            solidRenderer = undefined;
            return true;
          }
          return false;
        },
      };
    },
  },
  renderText({ node, options }) {
    return `${options.suggestion.char}${node.attrs.id ?? node.attrs.name}`;
  },
});

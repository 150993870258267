import { createResource, ErrorBoundary, For, Suspense } from "solid-js";
import { Spinner } from "~/components/_original/Spinner";
import { captureException } from "@repo/observability";
import { SecondaryCTA } from "~/components/cta/SecondaryCTA";
import { TbBulb, TbRefresh } from "solid-icons/tb";
import styles from "./ImprovePromptModal.module.css";
import { useWire } from "~/wire";
import { getRequestClient } from "@repo/client";
import { usePromptContext } from "~/domains/chat/prompt/PromptContext";
import { HiSolidPaperAirplane } from "solid-icons/hi";
import { StIcon } from "~/components/icons";
import { style } from "solid-js/web";

export const ImprovePromptModal = (props: { prompt: string; onClose: () => void }) => {
  const wire = useWire();
  const { editor, typePrompt } = usePromptContext();
  const client = getRequestClient(wire.services.identity.getIdentityToken);

  const [res, { refetch }] = createResource(async () => {
    const res = await client.controlplane.PromptEnhancer({
      type: "auto",
      prompt: props.prompt,
    });
    return res.data;
  });

  return (
    <div class={styles["improve-prompt-modal"]}>
      <ErrorBoundary
        fallback={(err, reset) => {
          captureException(err);

          return (
            <>
              <p class={styles["improve-prompt-modal__title"]}>
                <StIcon size={20} icon={TbBulb} class={styles["improve-prompt-modal__title-icon"]} />
                <span class={styles["improve-prompt-modal__title-text"]}>Improve your prompt</span>
              </p>
              <div class={styles["improve-prompt-modal__error"]}>
                <p class={styles["improve-prompt-modal__error-title"]}>
                  We couldn't generate better prompt suggestions.
                </p>
                <p class={styles["improve-prompt-modal__error-subtitl"]}>
                  Click to retry. If the issue persists, please contact our support team.
                </p>

                <div>
                  <SecondaryCTA
                    accessiblePrefix="Click to "
                    icon={TbRefresh}
                    data-test-id="retry-improve-prompt-btn"
                    label="Retry"
                    onClick={() => {
                      refetch();
                      reset();
                    }}
                  />
                </div>
              </div>
            </>
          );
        }}
      >
        <Suspense
          fallback={
            <>
              <p class={styles["improve-prompt-modal__title"]}>
                <StIcon size={20} icon={TbBulb} class={styles["improve-prompt-modal__title-icon"]} />
                <span class={styles["improve-prompt-modal__title-text"]}>Improve your prompt</span>
              </p>
              <div class={styles["improve-prompt-modal__loading"]}>
                <Spinner class={styles["improve-prompt-modal__loading-spinner"]} />
                <p class={styles["improve-prompt-modal__loading-text"]}>Now generating 3 improved versions</p>
              </div>
            </>
          }
        >
          <p class={styles["improve-prompt-modal__title"]}>
            <StIcon size={20} icon={TbBulb} class={styles["improve-prompt-modal__title-icon"]} />
            <span class={styles["improve-prompt-modal__title-text"]}>Choose an improved prompt</span>
          </p>
          <div class={styles["improve-prompt-modal__created"]}>
            <For each={res()?.prompt_enhanced.content}>
              {(item, index) => (
                <div
                  onClick={() => {
                    props.onClose();
                    typePrompt(item);
                    editor()?.commands.focus();
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      props.onClose();
                      typePrompt(item);
                      editor()?.commands.focus();
                    }
                  }}
                  role="button"
                  tabIndex="0"
                  class={styles["improve-prompt-modal__created-prompt"]}
                >
                  <p class={styles["improve-prompt-modal__created-prompt-title"]}>
                    {res()?.prompt_enhanced.summary[index()]}
                  </p>
                  <p class={styles["improve-prompt-modal__created-prompt-text"]}>{item}</p>
                  <HiSolidPaperAirplane size={20} class={styles["improve-prompt-modal__created-prompt-icon"]} />
                </div>
              )}
            </For>

            <SecondaryCTA
              class={styles["improve-prompt-modal__created-regenerate"]}
              accessiblePrefix="Click to "
              icon={TbRefresh}
              data-test-id="retry-improve-prompt-btn"
              label="Regenerate"
              onClick={() => {
                refetch();
              }}
            />
          </div>
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};

import {
  AnthropicModelsRegex,
  GoogleGeminiModelsRegex,
  OpenAIModelsRegex,
  MetaModelsRegex,
  DeepSeekModelsRegex,
} from "./extensions/LLMPicker/LLMPicker.extension";

type LLMPickResult = "google" | "openai" | "anthropic";
export const getFirstKnownModelMentionFromText = (text: string): LLMPickResult | undefined =>
  // Search for the index of each model mention with each model's regexes
  [
    { name: "openai" as LLMPickResult, index: text.search(OpenAIModelsRegex) },
    {
      name: "google" as LLMPickResult,
      index: text.search(GoogleGeminiModelsRegex),
    },
    {
      name: "anthropic" as LLMPickResult,
      index: text.search(AnthropicModelsRegex),
    },
    { name: "meta" as LLMPickResult, index: text.search(MetaModelsRegex) },
    {
      name: "deepseek" as LLMPickResult,
      index: text.search(DeepSeekModelsRegex),
    },
  ]
    // Filter out not found ones
    .filter((model) => model.index !== -1)
    // sort them by index descending (first mentioned last)
    .sort((m1, m2) => m2.index - m1.index)
    // pop the last element (which is the first mentioned model), if it exist return the company name
    .pop()?.name;

import { Plugin, PluginKey, TextSelection } from "@tiptap/pm/state";
import { Extension } from "@tiptap/core";
import { Decoration, DecorationSet } from "@tiptap/pm/view";

const OpenAIKeywords = [
  "gpt ?4o",
  "gpt ?4 ?mini",
  "gpt[- ]?(\\d+(\\.\\d+)?)",
  "openai",
  "open ?ai",
  "chat ?gpt",
  "gpt",
];

const GeminiKeywords = [
  "gemini ?pro", // Made the space optional and added word boundary
  "gemini ?ultra", // Made the space optional and added word boundary
  "gemini", // Standalone gemini with boundaries
];

const AnthropicKeywords = ["claude ?(\\d+(\\.\\d+)?)? ?(opus|sonnet|haiku)?", "opus", "sonnet", "haiku"];

const MetaKeywords = [
  "llama ?3[.]?[23]? ?[37]0b", // Matches llama 3.2 3b and llama 3.3 70b
  "llama ?3",
  "llama",
  "meta",
];

const DeepSeekKeywords = ["deepseek ?r1", "deepseek"];
const buildRegex = (keywords: string[]) =>
  new RegExp(
    // Add \b to so the keywords are treated as word boundaries requiring whitespace around them
    keywords
      .map((keyword) => `\\b${keyword}\\b`)
      .join("|"),
    // g - global regex (match multiple occurences), i - case insensitive regex
    "gi",
  );

export const AllKnownModelsRegex = buildRegex([
  ...OpenAIKeywords,
  ...GeminiKeywords,
  ...AnthropicKeywords,
  ...MetaKeywords,
  ...DeepSeekKeywords,
]);

/**
 * Matches any open ai models text, such as "chat gpt" or "gtp-3.5"
 * Used to highlight known models in the prompt bar and send the preference to our LLM router
 */
export const OpenAIModelsRegex = buildRegex(OpenAIKeywords);

/**
 * Matches any google gemini models text, such as "gemini" or "gemini ultra"
 * Used to highlight known models in the prompt bar and send the preference to our LLM router
 */
export const GoogleGeminiModelsRegex = buildRegex(GeminiKeywords);

/**
 * Matches any anthropic models text, such as "claude" or "claude 3.5"
 * Used to highlight known models in the prompt bar and send the preference to our LLM router
 */
export const AnthropicModelsRegex = buildRegex(AnthropicKeywords);

/**
 * Matches any meta models text, such as "llama" or "llama 3.3 70b"
 * Used to highlight known models in the prompt bar and send the preference to our LLM router
 */
export const MetaModelsRegex = buildRegex(MetaKeywords);

/**
 * Matches any deepseek models text, such as "deepseek" or "deepseek r1"
 * Used to highlight known models in the prompt bar and send the preference to our LLM router
 */
export const DeepSeekModelsRegex = buildRegex(DeepSeekKeywords);

// TODO: @andi - Use https://github.com/ueberdosis/tiptap/blob/main/packages/extension-link/src/link.ts as a base example
// for automatically detecting text in a prompt and port this quick and dirty code to the tip-tap implementation
export const LLMPickerExtension = Extension.create({
  name: "LLMPicker",

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey("LLM Picker"),
        state: {
          init() {
            return DecorationSet.empty;
          },
          apply(transaction, _prevState, _oldState, state) {
            let decorationSet = DecorationSet.empty;
            const selection = transaction.selection;
            const currentNode = transaction.doc.nodeAt(selection.$anchor.before());
            const decorations: Decoration[] = [];

            // Check if the selection is a TextSelection
            if (!(selection instanceof TextSelection) || !currentNode) {
              return decorationSet;
            }

            const { from, to } = selection;
            transaction.doc.nodesBetween(from, to, (p, pos) => {
              if (p.type.name === "paragraph") {
                const node = p.firstChild;
                if (!node?.isText) return;
                const text = node.text;
                const matches = text?.matchAll(AllKnownModelsRegex);
                // If matches found, create decorations
                if (matches) {
                  for (const match of matches) {
                    const matchIndex = (text?.indexOf(match[0]) || 0) + 1;
                    const matchFrom = pos + matchIndex;
                    const matchTo = matchFrom + match[0].length;
                    const mark = state.schema.marks.bold?.create();
                    const decoration = Decoration.inline(
                      matchFrom,
                      matchTo,
                      {
                        class:
                          "dark:bg-text-gradient capitalize bg-text-gradient-dark bg-clip-text text-transparent caret-black dark:caret-white",
                      },
                      { mark },
                    );
                    decorations.push(decoration);
                  }
                }
              }
            });

            // Create a new DecorationSet with the found decorations
            decorationSet = DecorationSet.create(transaction.doc, decorations);
            return decorationSet;
          },
        },
        props: {
          decorations(state) {
            return this.getState(state);
          },
        },
      }),
    ];
  },
});
